import { UserAgentApplication } from "msal";
import envVariables from "../util/envVariables";

var envVariablesObj = new envVariables();

var msalConfig = {
    auth: envVariablesObj.getAuthDataForAad(),
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
};



var requestObj = {
    scopes: ["user.read","profile"]
};

var myMSALObj = new UserAgentApplication(msalConfig);

var login = async () => {
    var authResult = await myMSALObj.loginPopup(requestObj);
    
    return authResult;
};

var getAccount = async () => {
    var account = await myMSALObj.getAccount();
    return account;
};

var logoff = () => {
    myMSALObj.logout();
};

export default {
    login,
    getAccount,
    logoff
};
