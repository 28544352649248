<template>
  <div>
    <!-- Header -->
    <div class="header bg-secondary py-6 py-lg-7 pt-lg-8">
     
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 3560 100" preserveAspectRatio="none" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-light" points="3560 0 3560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-white border-0 mb-0">
            
            <b-card-body class="px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-5">
                 <img src="img/brand/ho_full.svg" right height="100px" alt="Happy One" />                
              </div>
              <div class="text-center text-muted">
                <small>Meld je aan met je HappyNurse account van Microsoft</small>
              </div>
              <div class="text-center">
                   <!--<app-button class="my-4" @click="login" :block="true" size="md">Sign in</app-button>-->
                   <app-button type="primary" class="my-4" :loading="loading" :block="true" size="md" @click="login">Inloggen</app-button>
              </div>
              <div class="text-center text-danger" v-if="error_message != null">
                <small>{{error_message}}</small>
              </div>
            </b-card-body>
          </b-card>
          
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>

import aad from "../util/aad";
import axios from "@axios";
import UserSettings from '@core/scripts/UserSettings';

  export default {
    data() {
      return {
        model: {
          email: '',
          password: '',
          rememberMe: false,
        },
        error_message: null,
        loading: false,
        userSettings: new UserSettings()
      };
    },
    methods: {
      async login() {   
        this.error_message = null;     
        this.loading = true;
        aad.login().then(async (authResult) => {
        
        var account = authResult.account;
        var postData = {
            tenant_id: authResult.tenantId,
            email: account.userName,
            first_name: account.idToken.given_name,
            last_name: account.idToken.family_name,
            azure_user_id: account.accountIdentifier,
            token: authResult.idToken.rawIdToken
        }

        await axios.post(
            "aad/authorize", postData,
            )
            .then((response) => {
              
                if (response.data == null) {
                  this.error_message = "Inloggen mislukt, probeer het opnieuw."
                  this.loading = false;
                  return null;
                }
                if(process.env.NODE_ENV != 'production'){
                  this.$echo.connector.options.channelAuthorization.headers['CSRF'] = localStorage.getItem('CSRF');
                }
                localStorage.setItem('user', JSON.stringify(response.data.user_data));
                localStorage.setItem('userSettings', JSON.stringify(response.data.user_settings));
                localStorage.setItem("USER_LOCATIONS", JSON.stringify(response.data.user_locations));
                this.translations.addToDictionary(response.data.dictionary);
                localStorage.setItem('showTranslationKey', 'false');              

                this.$router.push(this.$route.query.redirect || '/');
            })
            .catch((error) => {
              this.error_message = "Inloggen mislukt, probeer het opnieuw."
              console.error(`error during authentication: ${error}`);
            }) .finally(() => {
                    this.loading = false;    
            });
        })
        .catch((error) => {
              this.error_message = "Inloggen mislukt, probeer het opnieuw."
              this.loading = false;
              console.error(`error during request ${error}`);
        });
      }
    }
  }
</script>
